<template>
  <div class="subscription">
    <Header />
    <b-container class="subscription-container">
      <b-row class="justify-content-md-start back-nav">
        <div class="col-lg-12 col-md-6">
          <b-link
            :to="(bond_type == 1 ? '/subscription' : '/redeem') + '-fbonds-calculator/' + product.bond_id + '/' + bond_type + '/' + product.product_id"><b-icon
              class="mx-2" icon="chevron-left"></b-icon><span>Kembali</span></b-link>
        </div>
      </b-row>

      <b-row class="justify-content-md-center p-4">
        <b-card class="subscription-review">
          <b-form>
            <div class="review-title">
              <b-row class="justify-content-center my-2">
                <h4>Investasi Anda</h4>
              </b-row>
            </div>
            <div class="fund-name">
              <b-row>
                <div class="col-2 col-lg-1 text-left mb-2">
                  <div v-if="product.image == null">
                    <img :src="'https://ui-avatars.com/api/?background=0B318F&color=fff&name=' +
                      product.bond_name
                      " />
                  </div>
                  <div v-else>
                    <img :src="`${imageCore}/images/products/${product.image}`" alt="Fund Image" />
                  </div>
                </div>
                <div class="col-10 px-4">
                  <h4>
                    {{ product.bond_name }}
                  </h4>
                  <p v-text="product.bond_name"></p>
                </div>
              </b-row>
            </div>

            <div v-if="bond_type == 1" class="total-purchase">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Available Cash Balance</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy || "IDR "
                    }}{{ formatCustom(balancePosition) }}
                  </p>
                </div>
              </b-row>
            </div>
            <div v-else class="total-purchase">
              <b-row class="mt-4">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Available Volume</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy || "IDR "
                    }} {{ formatCustom(avaibableBalance) }}
                  </p>
                </div>
              </b-row>
            </div>
            <hr />
            <div class="purchase-review">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Nominal {{ bond_type == 1 ? "Pembelian" : "Penjualan" }}</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.currency }} {{ formatCustom(invest_nominal) }}
                  </p>
                </div>
              </b-row>
            </div>
            <template v-if="bond_type == 2">
              <hr />
              <div class="purchase-review">
                <b-row class="mt-2">
                  <div class="col-6 my-auto text-left">
                    <p class="variant">Nominal Pokok Penjualan</p>
                  </div>
                  <div class="col-6 my-auto text-right">
                    <p class="value">
                      {{ product.currency }} {{ formatCustom(nominalPokok) }}
                    </p>
                  </div>
                </b-row>
              </div>
              <hr />
              <div class="purchase-review">
                <b-row class="mt-2">
                  <div class="col-6 my-auto text-left">
                    <p class="variant">Accurated Interest</p>
                  </div>
                  <div class="col-6 my-auto text-right">
                    <p class="value">
                      {{ product.currency }} {{ formatPriceDecimal(accuratedIntereset) }}
                    </p>
                  </div>
                </b-row>
              </div>
            </template>

            <template v-if="bond_type == 2">

              <hr />
              <div class="total-purchase">
                <b-row class="mt-2">
                  <div class="col-6 my-auto text-left">
                    <p class="variant">Total Hasil Penjualan</p>
                  </div>
                  <div class="col-6 my-auto text-right">
                    <p class="value">
                      {{ product.fund_ccy || "IDR " }}{{ formatPriceDecimal(parseInt(nominalPokok) +
                        parseInt(accuratedIntereset)) }}
                    </p>
                  </div>
                </b-row>
              </div>
            </template>

            <hr />
            <div class="subscription-cost">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Nominal Pokok</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy || "IDR " }}{{ formatPriceDecimal(parseInt(nominalPokok)) }}
                  </p>
                </div>
              </b-row>

            </div>

            <hr />
            <div class="subscription-cost pt-3">
              <b-row>
                <div class="col-6 text-left">
                  <small class="text-secondary pl-2">{{ bond_type == 1 ? "Accurated Interest" : "Pajak (10%)" }}</small>
                </div>
                <div class="col-6 my-auto text-right">
                  <p style="font-size: 14px;" class="value">
                    IDR {{ bond_type == 1 ? (formatPriceDecimal(accuratedIntereset)) : (biaya ? formatPriceDecimal(biaya -
                      10000) : "-") }}
                  </p>
                </div>
              </b-row>
              <b-row>
                <div class="col-6 text-left">
                  <small class="text-secondary pl-2">Bea Materai</small>
                </div>
                <div class="col-6 my-auto text-right">
                  <p style="font-size: 14px;" class="value">
                    IDR {{ biaya ? formatPriceDecimal(10000) : "-" }}
                  </p>
                </div>
              </b-row>
            </div>

            <hr />

            <div class="total-purchase">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Total Nominal Pembelian</p>
                </div>
                <div class="col-6 my-auto text-right">
                  <p class="value">
                    {{ product.fund_ccy || "IDR " }}{{ formatPriceDecimal(total) }}
                  </p>
                </div>
              </b-row>
            </div>

            <hr />
            <!-- <div class="custodian-bank">
              <b-row class="mt-2">
                <div class="col-6 my-auto text-left">
                  <p class="variant">Rekening Produk</p>
                </div>
                <div
                  class="col-6 my-auto text-right"
                  v-for="(value, index) in product.rekening"
                  :key="index"
                >
                  <p class="custodian-bank-name">
                    {{ value.bank.bank_name ? value.bank.bank_name : "-" }}
                  </p>
                  <p class="custodian-bank-name">
                    {{ value.account_number ? value.account_number : "-" }}
                  </p>
                  <p class="value mt-n3">
                    {{ value.account_name ? value.account_name : "-" }}
                  </p>
                </div>
              </b-row>
            </div> -->
            <div class="price-terms mt-4">
              <b-row>
                <div class="col-lg-12 col-md-6">
                  <p>
                    <img src="@/assets/img/icons/product/transaction-rules.svg" alt="Ketentuan Transaksi"
                      class="mx-2" />Ketentuan Transaksi
                  </p>
                </div>
              </b-row>
            </div>
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Transaksi sebelum 14:00 WIB</p>
              </b-row>
              <b-row>
                <p class="before-detail text-justify">
                  Batas waktu konfirmasi {{ bond_type == 1 ? "Pembelian" : "Penjualan" }} paling lambat
                  diterima dan tercatat oleh Aplikasi CGS iTrade Fund adalah pukul
                  14:00 WIB untuk diproses menggunakan harga di hari
                  bursa yang sama.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-after ml-3">
              <b-row class="mt-2">
                <p class="after">Transaksi setelah 14:00 WIB</p>
              </b-row>
              <b-row>
                <p class="after-detail text-justify">
                  Transaksi {{ bond_type == 1 ? "Pembelian" : "Penjualan" }} yang diterima dan tercatat melebihi batas
                  waktu pukul 14:00 WIB akan diproses dengan menggunakan harga
                  hari bursa berikutnya.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="transaction-time-before ml-3">
              <b-row class="mt-2">
                <p class="before">Biaya dan Perpajakan</p>
              </b-row>
              <b-row>
                <p class="before-detail">
                  Biaya dan Perpajakan akan timbul atas dasar ketentuan
                  peraturan perundangan-undangan yang berlaku, sesuai dengan
                  transaksi yang dimiliki.
                </p>
              </b-row>
            </div>
            <hr />
            <div class="agreement-checkbox">
              <b-row>
                <!-- <div class="custom-control custom-checkbox mb-4">
                  <input id="agree" type="checkbox" value="accepted" class="custom-control-input mr-2"
                    v-model="checked" />
                  <label for="agree" class="custom-control-label text-justify">
                    Saya telah membaca, memahami, dan menyetujui seluruh isi
                    <b-link :href="imageCore + '/prospectus/download/' + product.prospectus
                      " class="prospectus-link text-danger text-decoration-none" target="_blank">Prospektus</b-link>
                    dan memahami risiko investasi yang saya buat.
                  </label>
                </div> -->
                <b-button block
                  :disabled="!checked || loading || disabledButtonWhenNotFbonds || (bond_type == 1 && !product?.is_buy) || (bond_type == 2 && !product?.is_sell)"
                  @click="buy()" class="btn-tertiary py-2">
                  <div v-if="loading">
                    <div class="spinner-border spinner-border-sm"></div>
                    Loading
                  </div>
                  <div v-else>{{ this.bond_type == 2 ? 'Jual' : 'Bayar' }}</div>
                </b-button>
              </b-row>
            </div>
          </b-form>
        </b-card>
      </b-row>
    </b-container>
    <template>
      <b-modal id="modal-failed-buy" v-model="failedBuy" size="md" class="modal-failed-buy" centered hide-footer
        hide-header no-close-on-backdrop>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Nominal tidak memenuhi minimal {{ bond_type == 1 ? "Pembelian" : "Penjualan" }} produk
          </h1>
          <b-row class="justify-content-center">
            <b-button :to="`/subscription-fbonds-calculator/${bond_id}/${bond_type}`" block
              class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
          </b-row>
        </div>
      </b-modal>

      <!-- Can Buy Because Not Avaible Money -->
      <b-modal id="modal-failed-buy" v-model="failedMinSaldo" size="md" class="modal-failed-buy" centered hide-footer
        hide-header no-close-on-backdrop>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Saldo Tidak Mencukupi
          </h1>
          <b-row class="justify-content-center">
            <b-button :to="`/subscription-fbonds-calculator/${bond_id}/${bond_type}`" block
              class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
          </b-row>
        </div>
      </b-modal>

      <!-- Cant Buy Because in Cuttoff time -->
      <b-modal id="modal-failed-time" v-model="failedCutoffTime" size="md" class="modal-failed-buy" centered hide-footer
        hide-header no-close-on-backdrop>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h5 class="p-4 my-2 text-dark">
            Tidak dapat melanjutkan transaksi, karena sedang dalam cut-off time, silahkan coba lagi besok.
          </h5>
          <b-row class="justify-content-center">
            <b-button :to="`/subscription-fbonds-calculator/${bond_id}/${bond_type}`" block
              class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
          </b-row>
        </div>
      </b-modal>

      <!-- Can Sell Because Not Avaible Volume -->
      <b-modal id="modal-failed-buy" v-model="failedMinSell" size="md" class="modal-failed-buy" centered hide-footer
        hide-header no-close-on-backdrop>
        <div class="d-block text-center">
          <img src="@/assets/img/ekyc/data-not-correct.svg" alt="data-not-correct" />
          <h1 class="header-modal p-4 my-2 text-dark">
            Melebihi Jumlah Yang Bisa Dijual
          </h1>
          <b-row class="justify-content-center">
            <b-button :to="`/subscription-fbonds-calculator/${bond_id}/${bond_type}`" block
              class="mt-3 mx-2 btn-tertiary py-2">Kembali</b-button>
          </b-row>
        </div>
      </b-modal>


      <div class="modal-success-checkout">
        <b-modal id="waitingSalesModal" v-model="waitingSalesModal" ref="waitingSalesModal" size="md"
          class="modal-failed-register" centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <img src="@/assets/img/icons/transactions/checked-blue.svg" alt="" />
            <h1 class="header-modal p-4 my-2">
              Intruksi {{ bond_type == 1 ? "Pembelian" : "Penjualan" }} Anda telah kami terima
            </h1>
            <p>Mohon menunggu konfirmasi. Tim kami akan segera memproses</p>
            <b-row class="justify-content-center">
              <b-button to="/orders" block class="mt-3 mx-2 btn-tertiary py-2">Aktivitas Dalam Proses</b-button>
            </b-row>
          </div>
        </b-modal>
      </div>
    </template>
    <Footer />
    <ModalMessage :open="!!openModalMessage" :closeHandler="closeModalMessage" title="Terjadi Kesalahan"
      :bodyText="openModalMessage || ''" />
    <ModalNotRegister :isObligasi="true" :open="isModalNotRegisterOpen" :closeHandler="closeHandlerModalNotRegister" />
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Header from "@/components/partials/header/Header.vue";
import Footer from "@/components/partials/footer/Footer.vue";
// import moment from 'moment';
import numeral from 'numeral';
import ModalMessage from '@/components/partials/modal/message.vue';
import ModalNotRegister from '@/components/partials/modal/subscription/notRegister.vue';
const instance = axios.create({
  withCredentials: true,
});

export default {
  name: "SubscriptionReviewObligasi",
  metaInfo: {
    title: "Tinjauan Pembelian | CGS iTrade Fund",
  },
  components: {
    Header,
    Footer,
    ModalMessage,
    ModalNotRegister
  },
  data() {
    return {
      failedCutoffTime: false,
      product: {
        investment_manager: {
          im_name: "",
        },
      },
      balancePosition: [],
      fund_code: "",
      checked: true,
      invest_nominal: null,
      loading: false,
      processing: false,
      failedBuy: false,
      waitingSalesModal: false,
      imageCore: process.env.VUE_APP_IMAGE_URL,
      openModalMessage: false,

      // Dev
      bond_id: '',
      bond_type: '',
      harga: null,
      avaibableBalance: '',
      total: '',
      accuratedIntereset: '',
      nominalPokok: '',
      biaya: '',
      availableQouta: 0,
      // Render Condition
      failedMinSaldo: false, // Modal Saldo Tidak ada
      failedMinSell: false,
      isModalNotRegisterOpen: false,
      disabledButtonWhenNotFbonds: false
    };
  },
  computed: {
    ...mapGetters({
      bearer_token: "bearer_token",
      app_url: "app_url",
      user: "auth/user",
    }),
  },

  mounted() {
    this.bond_id = this.$route.params.bond_id;
    this.bond_type = parseInt(this.$route.params.bond_type);


    if (!this.user.individual?.is_fbonds) {
      this.isModalNotRegisterOpen = true;
      this.disabledButtonWhenNotFbonds = true;
    }

    if (localStorage.invest_nominal)
      this.invest_nominal = localStorage.invest_nominal;

    else if (this.invest_nominal == null) {
      this.failedBuy = true;
    }

    if (this.bond_type == 2 && !this.$route.params.product_id) {
      this.$router.push('/portfolio');
    }

    this.harga = localStorage.getItem('harga');
    this.invest_nominal = localStorage.getItem('invest_nominal');
    this.accuratedIntereset = localStorage.getItem('accurated_interest');
    this.nominalPokok = localStorage.getItem("nominal_pokok");
    this.biaya = localStorage.getItem("biaya");

    if (this.bond_type == 1) {
      this.total = localStorage.getItem('total');
    } else {
      this.total = (parseInt(this.nominalPokok) + parseInt(this.accuratedIntereset)) - this.biaya + 20000 // 20rb di dapat karena variabel biaya sudah termasuk materai
    }

    Promise.all([this.fetchFund(),
    this.fetchBalancePositions(), this.fetchPortfolioObligasi(), this.fetchProductQouta(), this.checkCuttOff()]).then(() => {

      this.openModalMessage = false;

      if (!this.product.is_sell && this.bond_type == 2) {
        this.openModalMessage = `Produk tidak dapat dijual`;
      } else if (!this.product.is_buy && this.bond_type == 1) {
        this.openModalMessage = `Produk tidak dapat dibeli`;
      }

      // Cegah Jika sudah melakukan transaksi tetapi melakukan action back
      if (!this.invest_nominal || !this.harga || !this.harga || !this.accuratedIntereset || !localStorage.getItem('nominal_pokok') 
          || !localStorage.getItem('materai') || !localStorage.getItem('nominal_total') || !localStorage.getItem('biaya_penjualan')
          || !localStorage.getItem('pajak')
        ) {
        this.$router.replace(`/subscription-fbonds-review/${this.bond_id}/${this.bond_type}`);
      }
    }).catch(err => {
      this.openModalMessage = `Terjadi kesalahan didalam aplikasi. Kesalahan: ${err?.message || "Terjadi Kesalahan"}`;
      console.log(err);
    })
  },

  methods: {
    async fetchFund() {
      let response = await axios({
        method: "GET",
        url: `${this.app_url}fbonds/product?bond_id=${this.bond_id}`,
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.data.data) {
        return this.$router.push("/NOT-FOUND");
      }

      this.product = response.data.data[0];
    },
    async fetchBalancePositions() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}balance/position?client_id=${this.user?.individual?.client_id}`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
      });

      this.balancePosition = response?.data?.data?.amount || 0;
    },

    async checkCuttOff() {
      let response = await instance({
        method: "GET",
        url: `${this.app_url}config`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
      });

      const cuttoff_time = response?.data?.data?.find(candidate => candidate.type == 1)?.value;

      if (cuttoff_time) {
        const currentDate = new Date();
        const cutoffDate = new Date();
  
        // Parse cutoff time string (assuming it's in "HH:mm" format)
        const [cutoffHours, cutoffMinutes] = cuttoff_time.split(':');
        cutoffDate.setHours(cutoffHours, cutoffMinutes, 0, 0);
  
        return currentDate > cutoffDate;
      }

      return true;
    },

    async buy() {
      const notAvaibable = parseFloat(this.total);
      const balancePosition = parseFloat(this.balancePosition);

      // Jika Saldo Tidak Mencukupi
      if (((!balancePosition) || (balancePosition < notAvaibable)) && this.bond_type == 1) {
        this.failedMinSaldo = true;
        return;
      }
      // Waktu Jual
      if ((!this.avaibableBalance || (this.avaibableBalance < notAvaibable)) && this.bond_type == 2) {
        this.failedMinSell = true;
        return
      }

      // Jika melewati batas waktu cuttoff
      if(await this.checkCuttOff()){
        this.failedCutoffTime = true;
        return;
      }

      if (this.availableQouta < parseFloat(this.invest_nominal)) {
         this.openModalMessage = `Kouta obligasi telah habis. Silakan hubungi CGS iTrade Fund +62 21 5151330`
         return;
      }

      // Check Jika sudah melebihi kouta
      this.$router.push(`/subscription-fbonds-verification/${this.bond_id}/${this.bond_type}`);
    },
    formatPriceCustom(value) {
      let val = Math.floor(value).toFixed(0).replace(".", ",");
      // let val = (value/1).toFixed(2).replace('.', ',')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatCustom(value) {
      return numeral(value).format('0,0,[0000]')
    },
    formatPriceDecimal(value) {
      const valArray = parseFloat(value).toFixed(3).split(".");
      const formattedIntegerPart = valArray[0].replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ","
      );
      // const formattedDecimalPart = valArray[1]?.substr(0, 3) || "000";
      // console.log("Value", value)
      // console.log("Integer part", formattedIntegerPart);
      // console.log("Decimal part", formattedDecimalPart);
      // return `${formattedIntegerPart},${formattedDecimalPart}`;
      return formattedIntegerPart;
    },
    async fetchPortfolioObligasi() {
      let response = await instance({
        method: "POST",
        url: `${this.app_url}fbonds/portofolio`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `${this.access_token}`,
        },
        data: {
          "action": "portfolio",
          "user": "Pollux",
          "client_id": `${this.user.individual.client_id}`
        }
      });

      const data = (response?.data?.meta?.type == 1 ? response?.data?.data?.portfolio : response?.data?.data) || [];
      this.avaibableBalance = data.filter(candidate => candidate.stock === this.bond_id).reduce((prev, current) => {
        return prev + (parseFloat(current.available))
      }, 0);
    },
    closeModalMessage() {
      this.openModalMessage = false
    },
    closeHandlerModalNotRegister() {
      this.isModalNotRegisterOpen = false
    },
    async fetchProductQouta() {
      // this.loading = true;

      let response = await axios({
        method: "GET",
        url: `${this.app_url}fbonds/product/check-quota?bond_id=${this.bond_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `${this.bearer_token}`,
        },
      });

      this.availableQouta = response?.data?.available_quota || 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.subscription {
  background: #f3f4f6;
}

.subscription-container {
  padding-top: 140px;
  padding-bottom: 120px;
}

.subscription-review {
  padding: 20px;
}

h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 36px;

  color: #111827;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #4b5563;
}

.back-nav {
  padding: 0px 260px 0px 248px;

  a {
    font-family: $inter;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #6b7280;
    text-decoration: none;
  }
}

.subscription-review {
  width: 600px;
}

.review-title {
  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
  }
}

.fund-name {
  img {
    border-radius: 6px;
    width: 40px;
  }

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;

    color: #111827;
    margin-bottom: 0px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;

    color: #6b7280;
  }
}

hr {
  margin: 0 !important;
}

.purchase-review {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.subscription-cost {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.total-purchase {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }

  .value {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    color: #111827;
  }
}

.custodian-bank {
  .variant {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #4b5563;
  }

  .custodian-bank-name {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #111827;
  }

  .value {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    color: #111827;
  }
}

.price-terms p {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;

  color: #111827;
}

.transaction-time-before {
  .before {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .before-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.transaction-time-after {
  .after {
    font-style: normal;
    font-weight: bold;
    font-size: 16px;

    color: #111827;
  }

  .after-detail {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    color: #4b5563;
  }
}

.agreement-checkbox {
  margin-top: 40px;
  padding-left: 18px;
  padding-right: 18px;

  h3 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;

    color: #374151;
  }
}

.custom-checkbox .custom-control-input:checked~.custom-control-label::before {
  background-color: $secondaryColor;
}

.custom-checkbox:active {
  box-shadow: none !important;
}

.custom-checkbox:focus {
  box-shadow: none !important;
}

.header-modal {
  font-size: 32px;
}

@media only screen and (max-width: 768px) {
  .back-nav {
    padding: 0px;
  }

  h4 {
    font-size: 16px !important;
  }

  p {
    font-size: 14px !important;
  }

  label {
    font-size: 14px !important;
  }

  .header-modal {
    font-size: 16px !important;
  }
}</style>
